import { StrictMode, useEffect, useRef, useState } from 'react';

import { getStyles } from '@themes/index';
import { CardPortrait } from '@game/common/CardPortrait';
import { TwistIcon } from '../../react/components/StepWorldTwist/TwistIcon';
import LoadingSpinner from '@game/common/LoadingSpinner';

import type { Entity } from '@hiddendoor/shared';
import { feed, type StoryFeedCardData } from '@api/game-server/client';

// Adapted from Dan Abramov's example: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// Permission is granted in the link above to reuse/adapt the original code
function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback?.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const REFRESH_DELAY_MS = 10000; // 10 seconds

async function getData(limit?: number, worlds?: string[], createdAfter?: Date) {
  try {
    const data = await feed.list(limit, worlds, createdAfter);
    return data;
  } catch (error) {
    console.log(error);
  }
}

interface FeedListProps {
  limit?: number;
  worlds?: string[];
  createdAfter?: Date;
  useThemeBackground?: boolean;
}

const FeedList = ({
  limit,
  worlds,
  useThemeBackground = false,
}: FeedListProps) => {
  const [items, setItems] = useState<StoryFeedCardData[]>([]);

  async function fetchItems(
    isFirstFetch: boolean,
    oldItems: StoryFeedCardData[],
  ) {
    // On subsequent requests, we want to only get new data since our last request
    const timestamp = isFirstFetch
      ? undefined
      : new Date(Date.now() - REFRESH_DELAY_MS);

    getData(limit, worlds, timestamp).then((newItems) => {
      if (newItems?.length) {
        let newData = [...newItems, ...oldItems];

        if (limit && newData.length > limit) {
          newData = newData.slice(0, limit);
        }

        setItems(newData);
      }
    });
  }

  // Initial fetch on load
  useEffect(() => {
    fetchItems(true, items);
  }, []);

  // Set up recurring fetches on an interval while maintaining a reference to the latest `items` state
  useInterval(() => {
    fetchItems(false, items);
  }, REFRESH_DELAY_MS);

  return (
    <StrictMode>
      {items ? (
        <div className="relative grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 snap-y">
          {items.map((story, i) => (
            <div
              key={i}
              className="motion-safe:animate-fadeIn motion-safe:opacity-0 h-full snap-center"
              style={{
                animationDelay: `${i * 100}ms`,
                animationFillMode: 'forwards',
              }}
            >
              <StoryFeedCard
                story={story}
                useThemeBackground={useThemeBackground}
              />
            </div>
          ))}
          <div className="flex justify-center">
            <svg
              width="200"
              height="306"
              viewBox="0 0 200 306"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.7">
                <path
                  d="M26.5742 273.979V246.007C26.5742 244.462 27.8266 243.21 29.3714 243.21H199.301"
                  stroke="#888888"
                  strokeWidth="2.7972"
                />
                <path
                  d="M54.5449 243.909V215.937C54.5449 214.392 55.7973 213.14 57.3421 213.14H199.999"
                  stroke="#888888"
                  strokeWidth="2.7972"
                />
                <path
                  d="M81.1191 213.139V185.167C81.1191 183.622 82.3715 182.37 83.9163 182.37H200"
                  stroke="#888888"
                  strokeWidth="2.7972"
                />
                <path
                  d="M107.691 183.768V155.796C107.691 154.251 108.944 152.999 110.489 152.999H199.999"
                  stroke="#888888"
                  strokeWidth="2.7972"
                />
                <path
                  d="M132.867 151.6V123.628C132.867 122.083 134.12 120.831 135.664 120.831H200"
                  stroke="#888888"
                  strokeWidth="2.7972"
                />
                <path
                  d="M1.39844 273.979H199.301"
                  stroke="#888888"
                  strokeWidth="2.7972"
                />
                <path
                  d="M1.3986 100.552C1.3986 46.0957 45.544 1.95036 100 1.95036C154.456 1.95036 198.601 46.0957 198.601 100.552V299.852C198.601 302.17 196.723 304.048 194.406 304.048H5.59441C3.27713 304.048 1.3986 302.17 1.3986 299.852V100.552Z"
                  stroke="#888888"
                  strokeWidth="2.7972"
                />
              </g>
            </svg>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </StrictMode>
  );
};

const StoryFeedCard = ({
  story,
  useThemeBackground,
}: {
  story: StoryFeedCardData;
  useThemeBackground: boolean;
}) => {
  const getPronouns = (entity: Entity): string => {
    const pronouns = {
      they: 'they/them',
      he: 'he/him',
      she: 'she/her',
      it: '',
    };

    return pronouns[entity.pronoun] || '';
  };

  const getHighConcept = (entity: Entity): string => {
    if (!entity.description) {
      const aspectWithHighConcept = entity.aspects.find((aspect) =>
        aspect.category.includes('high_concept'),
      );
      return aspectWithHighConcept?.aspect ?? '';
    } else {
      return entity.description;
    }
  };

  const world = story.world;
  const character = world.entities[0];
  const artifact = story.artifact;
  // const date = new Date(story.updatedAt).toLocaleDateString('en-US', {
  //   year: 'numeric',
  //   month: 'long',
  //   day: 'numeric',
  // });

  const textClasses = useThemeBackground
    ? 'text-standard hover:text-standard'
    : 'text-marketing-dark hover:text-marketing-dark';
  const borderClasses = useThemeBackground
    ? 'border-standard'
    : 'border-marketing-dark';

  return (
    <div className="space-y-8 pb-8" style={getStyles(world.worldSeed)}>
      <div
        className={`w-full h-px border ${borderClasses} border-dotted`}
      ></div>

      <a
        href={`/view/${story.storyId}`}
        className={`flex flex-col items-center gap-8 ${textClasses} hover:scale-[1.02] transform transition-transform duration-300 ease-in-out`}
      >
        {/* Chapter Card */}
        <div className="w-full sm:h-80 sm:px-5 flex flex-col gap-1.5">
          <div className="w-full h-9 flex items-center justify-between gap-2.5">
            <div className="uppercase truncate text-sm">
              {world.displayName}
            </div>
            <div className="flex items-center">
              {world.twistNames.map((twistName, i) => (
                <div
                  className="w-9 h-9 flex items-center justify-center rounded-full"
                  key={i}
                >
                  <TwistIcon name={twistName} size={24} />
                </div>
              ))}
            </div>
          </div>
          <div className="pb-3 flex flex-col gap-3">
            <div className="text-3xl font-bold font-title capitalize leading-9">
              {artifact?.storyName || story.name}
            </div>
            <div
              className="sm:h-36"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 6,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {artifact?.storyLogline}
            </div>
          </div>
          {/*<div className="h-4">{date}</div>*/}
        </div>

        {/* Character Card */}
        <div
          className={`w-56 h-80 p-5 rounded-lg border ${borderClasses} flex flex-col items-center gap-5`}
        >
          <div className="w-36 h-36 rounded-full justify-center items-center inline-flex">
            <div className="flex items-center justify-center rounded-full overflow-hidden bg-themeBackground dark:bg-darkMode-themeBackground">
              <CardPortrait entity={character} size="w-36 h-36" />
            </div>
          </div>
          <div className="self-stretch h-24 flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch text-center text-xs font-bold uppercase leading-3 tracking-wide">
              {getPronouns(character)}
            </div>
            <div className="self-stretch text-center text-xl font-bold capitalize leading-normal tracking-wide">
              {character?.name}
            </div>
            <div className="self-stretch text-center text-base capitalize leading-tight">
              {getHighConcept(character)}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default FeedList;
